import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BannerHomeRightKid from "../Assets/images/home-banner-right.png";
import BannerHomeRightKidBefore from "../Assets/images/home-banner-right-bef.png";
import LeftBottomHomeBannerImg from "../Assets/images/homebanner-left-bottom.png";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import headerhover from "../Assets/images/menuhower.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  faCircleXmark,
  faEnvelope,
  faLocationDot,
  solid,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BannerHome = () => {





  const [show, setShow] = useState(false);
  const location = useLocation();

  const [formData, setFormData] = useState({
    full_name: "",
    date_of_birth: "",
    start_date: "",
    parent_name: "",
    parent_mobile: "",
    parent_email: "",
    other_info: ""
  })
  // console.log("formData>>", formData)


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormChange = (key, value) => {
    setFormData({ ...formData, [key]: value })
  }

  const handleFormSubmit = async () => {
    // e.preventDefault(); // Prevent the default form submission behavior
    // console.log("form submission start>>", formData)

    // Validate required fields
    const requiredFields = ['full_name', 'start_date', 'parent_mobile', 'date_of_birth', 'parent_name', 'parent_email'];
    const missingFields = requiredFields.filter(field => !formData[field]);

    if (missingFields.length > 0) {
      console.error(`Please fill in all required fields.`);
      toast.error('Please fill in all required fields.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.parent_email)) {
      // Show an error message or handle the invalid email case
      console.error("Invalid email format");
      toast.error('Invalid email format', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    try {
      // console.log("url>>>", `${process.env.REACT_APP_BASE_URL}/register`,)
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/register`,
        formData,
      )
      // console.log("res>>", res)

      if (res.status === 200) {
        toast.success('Form submitted successfully', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        setFormData({
          full_name: "",
          date_of_birth: "",
          start_date: "",
          parent_name: "",
          parent_mobile: "",
          parent_email: "",
          other_info: ""
        })

        setTimeout(() => {
          handleClose();
        }, 1500); // Adjust the delay time as needed
      }


    }
    catch (e) {
      // console.error("err>>", e)
      if (e.response?.status === 400) {
        // console.log(e?.response?.data?.message)
        toast.error(e?.response?.data?.message || "Form submission failed. Please try again later.", {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      else {
        // console.log("Form submission failed. Please try again later.")
        toast.error("Form submission failed. Please try again later.", {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        handleClose()
      }
    }


  }

  const isPageActive = (path) => {
    // Check if the current path matches the provided path
    return location.pathname === path;
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  };

  return (
    <>
    <section className="banner-home-sec bg-banner-gradient position-relative">
      <div className="content-parent-banner-home position-relative">
        <div className="container-1440">
          <div className="row banner-row align-items-center">
            <div className="col-6 solongo-banner-res">
              <div className="left-banner-home">
                <h3 className="banner-home-first-heading mb-0">Welcome to</h3>
                <h2 className="banner-home-second-heading text-white">
                  Solongo Early <br /> Intervention
                </h2>
                <p className="banner-home-para text-white">
                  A Leading Certified Early Start Denver Model (Esdm) Early
                  <br />
                  Intervention Provider in Melbourne.
                </p>
                <div className="left-banner-home-btn-parent d-flex align-items-center gap-3">
                  <Link onClick={handleShow} className="pull-pulse left-banner-home-btn solongo-btn-res left-banner-home-btn-bg-yell yellow-btn-hover">
                    BOOK A FREE CONSULTATION
                  </Link>
                  <Link target="blank" to="https://youtu.be/jiMwvT91bq8?si=qHVkZxtnokBUs-Wu" className="pull-pulse left-banner-home-btn left-banner-home-btn-trans">
                    WATCH VIDEO ABOUT ESDM
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6 solongo-banner-res">
              <div className="right-banner-home position-relative d-flex align-items-center justify-content-center">
                <div className="right-banner-home-img">
                  <img
                    className="position-relative home-banner-float-anim right-banner-home-img-upper"
                    src={BannerHomeRightKid}
                    alt=""
                  />
                  <img
                    className="right-banner-home-img-lower position-absolute"
                    src={BannerHomeRightKidBefore}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-home-bg-slide-parent">
        {/* <h3 className="banner-home-bg-slide banner-home-bg-slide-one">EARLY</h3>
        <h3 className="banner-home-bg-slide banner-home-bg-slide-two">
          INTERVENTION
        </h3> */}
      </div>
      <div className="left-bottom-home-banner home-banner-float-anim position-absolute">
        <img src={LeftBottomHomeBannerImg} alt="" />
      </div>
    </section>

<Modal
className="Register-modal"
show={show}
aria-labelledby="contained-modal-title-vcenter"
centered
>
<div className="Therapy-Registration-Modal">
  <div onClick={handleClose} className="modal-xmark">
    <FontAwesomeIcon icon={faCircleXmark} />
  </div>
  <h2>ESDM Therapy Registration</h2>
  <p>
    We are thrilled to embark on this journey with you and your child.
    Our Early Start Denver Model <br />
    (ESDM) therapy is designed to provide individualized support,
    fostering your child's development
    <br />
    and enhancing their social and communication skills.
  </p>
  <p>
    To get started, please fill out the registration form below. Your
    input will help us tailor our approach
    <br />
    to meet your child's unique needs effectively. We look forward to
    partnering with you on this
    <br />
    transformative path towards growth and progress.
  </p>
  <h3>
    Thank you for choosing us to be a part of your child's developmental
    journey!
  </h3>
</div>
<form>
  <div className="row input-forms">
    <div className="col-lg-6 ">
      <div className="information-forms">
        <label>Child Full Name:</label>
        <input type="text" name="" id="" value={formData.full_name} onChange={(e) => { handleFormChange("full_name", e.target.value) }} />
      </div>
      <div className="information-forms">
        <label>Prefered Start Date:</label>
        <input type="date" name="" id="" value={formData.start_date} onChange={(e) => { handleFormChange("start_date", e.target.value) }} />
      </div>
      <div className="information-forms">
        <label>Parent/Guardian’s Mobile:</label>
        <input type="number" name="" id="" value={formData.parent_mobile} onChange={(e) => { handleFormChange("parent_mobile", e.target.value) }} />
      </div>
    </div>
    <div className="col-lg-6">
      <div className="information-forms">
        <label>Child’s Date of Birth:</label>
        <input type="date" name="" id="" value={formData.date_of_birth} onChange={(e) => { handleFormChange("date_of_birth", e.target.value) }} />
      </div>
      <div className="information-forms">
        <label>Parent/Guardian’s Name:</label>
        <input type="text" name="" id="" value={formData.parent_name} onChange={(e) => { handleFormChange("parent_name", e.target.value) }} />
      </div>
      <div className="information-forms">
        <label>Parent/Guardian’s Email:</label>
        <input type="email" name="" id="" value={formData.parent_email} onChange={(e) => { handleFormChange("parent_email", e.target.value) }} />
      </div>
    </div>
  </div>
  <div className="Other-information">
    <div className="information-forms">
      <label>Other information:</label>
      <textarea value={formData.other_info} onChange={(e) => { handleFormChange("other_info", e.target.value) }}></textarea>
    </div>
    <div className="Modal-send-btn" onClick={handleFormSubmit}>
      <Link className="send" to="" >
        Send
      </Link>
    </div>
  </div>
</form>
<ToastContainer />
</Modal>

</>


  );
};

export default BannerHome;

import React from "react";
import bannercircle from "../Assets/images/Bannercircle.png";
import sec1right from "../Assets/images/sec1-right-img.png";
import Bannerbottom from "../Assets/images/Home-Bannerbottom.png";
import sec1bg from "../Assets/images/Sec1bg.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";



const HomeAbout = () => {

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  };


  return (
    <section className="homeabout-sec sec1 position-relative">
      <div className="cloudimg cloudimg-home">
        <img className="bannerbottom" src={Bannerbottom} alt="" />{" "}
      </div>
      <div className="home-about-min-parent">
        <div className="homeabout-sec-btm-left-circle">
          <img className="bannercircle-rotate" src={bannercircle} alt="" />
          {/* test */}
        </div>
        <div className="homeabout-sec-btm-right-circle">
          <img className="bannercircle-rotate" src={bannercircle} alt="" />
        </div>
        <img className="sec1bg" src={sec1bg} alt="" />
        <img className="sec1right" src={sec1right} alt="" />
        <div className="container-1440">
          <div className="sec1-text homeabout-sec-content">
            <h2>ABOUT US</h2>
            <p>
              At Solongo Early Intervention, we are dedicated to fostering the
              development and 
              growth of children through the Early Start Denver Model (ESDM).
              Our specialized
              intervention services cater to children on the autism spectrum or
              those who
              exhibit developmental delays.
            </p>
            <p>
              We are committed to delivering evidence-based practices that
              prioritize the
              developmental well-being of children. Our expert team specializes
              in implementing
              the renowned ESDM, a comprehensive intervention program firmly
              grounded in the
              principles of Applied Behavior Analysis (ABA) and developmental
              science.
            </p>
            <p>
              We personalize interventions to suit each child's unique needs,
              focusing on building
              crucial skills in communication, social interaction, play skills
              fine and gross motor
              skills, joint attention, self-care, and behaviour management.
            </p>
            <div className="home-about-btn---parent">
              <Link onClick={scrollTop} to="/About" className="pull-pulse home-about-btn yellow-btn-hover">
                LEARN MORE
                <FontAwesomeIcon icon={faArrowRightLong} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
};

export default HomeAbout;

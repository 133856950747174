import React from "react";
import HomeServLeftKid from "../Assets/images/home-serv-kid.png";
import Servoneimg from "../Assets/images/sec2-certified.png";
import Servtwoimg from "../Assets/images/sec2-comprhensive.png";
import Servthreeimg from "../Assets/images/sec2-evidence-based.png";
import Servfourimg from "../Assets/images/Sec2-individualized.png";
import Servfiveimg from "../Assets/images/sec2-collaborative.png";
import Servsiximg from "../Assets/images/sec2-family-centred.png";
import HomeServcircle from "../Assets/images/Bannercircle.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";


const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'instant'
  });
};

const HomeServices = () => {

  return (
    <section className="home-services-sec position-relative">
      <img
        className="home-serv-top-img bannercircle-rotate"
        src={HomeServcircle}
        alt=""
      />
      <div className="content-parent-home-services-sec">
        <div className="container-1440">
          <div className="row res-our-service justify-content-between position-relative">
            <div className="col-3">
              <div className="left-kid-home-serv-parent">
                <img
                  className="left-kid-home-serv-img"
                  src={HomeServLeftKid}
                  alt=""
                />
              </div>
            </div>
            <div className="col-8 res-our-service-sec">
              <div className="right-home-services-parent text-center">
                <h2 className="text-white home-serv-heading">OUR SERVICES</h2>
                <div className="row home-services-row-gap home-services-row-gap-res">
                  <div className="col-4">
                    <div className="img-text-parent-of-home-serv">
                      <img
                        className="services-homeimg"
                        src={Servoneimg}
                        alt=""
                      />
                      <h5 className="services-hometext">
                        Certified ESDM Expertise
                      </h5>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="img-text-parent-of-home-serv">
                      <img
                        className="services-homeimg"
                        src={Servtwoimg}
                        alt=""
                      />
                      <h5 className="services-hometext">
                        Comprehensive Assessments
                      </h5>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="img-text-parent-of-home-serv">
                      <img
                        className="services-homeimg"
                        src={Servthreeimg}
                        alt=""
                      />
                      <h5 className="services-hometext">
                        Evidence-Based Practices
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="row home-services-row-gap">
                  <div className="col-4">
                    <div className="img-text-parent-of-home-serv">
                      <img
                        className="services-homeimg"
                        src={Servfourimg}
                        alt=""
                      />
                      <h5 className="services-hometext">
                        Individualized Approach
                      </h5>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="img-text-parent-of-home-serv">
                      <img
                        className="services-homeimg"
                        src={Servfiveimg}
                        alt=""
                      />
                      <h5 className="services-hometext">
                        Collaborative Environment
                      </h5>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="img-text-parent-of-home-serv">
                      <img
                        className="services-homeimg"
                        src={Servsiximg}
                        alt=""
                      />
                      <h5 className="services-hometext">Family-Centred Care</h5>
                    </div>
                  </div>
                </div>
                <div className="home-serv-btn---parent">
                  <Link onClick={scrollTop} className="pull-pulse home-about-btn yellow-btn-hover">
                    LEARN MORE
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeServices;

import { useEffect } from "react";

const useAnalytics = () => {
  useEffect(() => {
    let dataLayer = window.dataLayer || [];

    dataLayer.push({
      event: "pageView",
    });

    window.addEventListener("message", listenToRegisterFormSubmit);

    return () => {
      window.removeEventListener("message", listenToRegisterFormSubmit);
    };
  }, []);
};

export { useAnalytics };

function listenToRegisterFormSubmit(e) {
  if (
    e.origin.includes("jotform.com") &&
    e.data.action === "submission-completed"
  ) {
    let dataLayer = window.dataLayer || [];

    dataLayer.push({
      event: "generate_lead",
    });
  }
}

import React, { useState } from "react";
import Header from "../Components/Header";
import BannerHome from "../Components/BannerHome";
import HomeAbout from "../Components/HomeAbout";
import HomeServices from "../Components/HomeServices";
import HomeESDM from "../Components/HomeESDM";
import HomeRaise from "../Components/HomeRaise";
import HomeMeetTeam from "../Components/HomeMeetTeam";
import Footer from "../Components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpLong } from "@fortawesome/free-solid-svg-icons";
import { useAnalytics } from "../Components/useAnalytics";
import Meta from "../Components/metas";

const Home = () => {

  useAnalytics();

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Meta title="Solongo Early Intervention | Early Childhood Intervention " description="Specialized early childhood intervention program in Melbourne with expert therapists providing tailored services for children with developmental needs." />

      <main className="main-home-tag">
        <div className="fixed-btm-home-page-arrow">
          <FontAwesomeIcon onClick={scrollTop} icon={faArrowUpLong} />
        </div>
        <div className="home-banner-header-parent">
          <Header isOpen={isOpen} />
          <BannerHome />
        </div>
        <div className="pd-top-100">
          <div className="for-responsive">
            <HomeAbout />
            <HomeServices />
            <HomeESDM />
            <HomeRaise />
            <HomeMeetTeam />
            <Footer />
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;

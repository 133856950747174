import React, { useEffect, useRef } from "react";
import Header from "../Components/Header";
import bannerbg from "../Assets/images/Bannerimg.png";
import Bannerbottom from "../Assets/images/AboutBanner.png";
import bannercircle from "../Assets/images/Bannercircle.png";
import sec1bg from "../Assets/images/Sec1bg.png";
import sec1right from "../Assets/images/About-Aboutright.png";
import box1 from "../Assets/images/sec2-certified.png";
import box2 from "../Assets/images/sec2-comprhensive.png";
import box3 from "../Assets/images/sec2-evidence-based.png";
import box4 from "../Assets/images/Sec2-individualized.png";
import box5 from "../Assets/images/sec2-collaborative.png";
import box6 from "../Assets/images/sec2-family-centred.png";
import box7 from "../Assets/images/sec2-training-support.png";
import sec3sharon from "../Assets/images/sec3-meetourteam.png";
import ABoutourteam from "../Assets/images/About-meet-ourteam2.png";
import Footer from "../Components/Footer";
import { useAnalytics } from "../Components/useAnalytics";

const About = () => {
  useAnalytics();

  return (
    <>
      <div className="About">
        <Header />
        <div className="About-banner">
          <img
            className="bannercircle bannercircle-rotate"
            src={bannercircle}
            alt=""
          />
          <div className="container-1440">
            {/* <div className="Banner-text-res">
              <h1>About Us</h1>
              <h3>
                a leading certified Early Start Denver Model (ESDM) Early{" "}
                <br />
                Intervention provider in Melbourne.
              </h3>
            </div> */}
            <div className="banner-content">
              <div className="Banner-text">
                <h1>About Us</h1>
                <h3>
                  a leading certified Early Start Denver Model (ESDM) Early{" "}
                  <br />
                  Intervention provider in Melbourne.
                </h3>
              </div>
            </div>
            <div className="Banner-pic">
              <img src={sec1bg} alt="" />
            </div>
          </div>
        </div>
        <div className="sec1">
          <div className="cloudimg">
            {" "}
            <img className="bannerbottom" src={Bannerbottom} alt="" />{" "}
          </div>
          <img
            className="sec1circle bannercircle-rotate"
            src={bannercircle}
            alt=""
          />
          <img className="sec1bg" src={sec1bg} alt="" />
          <img className="sec1right" src={sec1right} alt="" />
          <div className="container-1440">
            <div className="sec1-text homeabout-sec-content">
              <h2 className="custom-approachpd">OUR APPROACH</h2>
              <p>
                At Solongo Early Intervention, we are dedicated to fostering the
                development and growth of children through the Early Start
                Denver Model (ESDM). Our specialized intervention services cater
                to children on the autism spectrum or those who exhibit
                developmental delays.
              </p>
              <p>
                We are committed to delivering evidence-based practices that
                prioritize the developmental well-being of children. Our expert
                team specializes in implementing the renowned ESDM, a
                comprehensive intervention program firmly grounded in the
                principles of Applied Behavior Analysis (ABA) and developmental
                science.
              </p>
              <p>
                We personalize interventions to suit each child's unique needs,
                focusing on building crucial skills in communication, social
                interaction, play skills fine and gross motor skills, joint
                attention, self-care, and behaviour management.
              </p>
              <p>
                As a trusted early intervention provider, we pride ourselves on
                personalizing interventions to address the unique needs of each
                child. We understand the significance of early intervention in
                fostering positive developmental outcomes, and our mission is to
                empower children to reach their full potential.
              </p>
            </div>
          </div>
        </div>
        <div className="sec2">
          <h2>OUR SERVICES</h2>
          <div className="container-1440">
            <div className="sec2-service">
              <img
                className="service-circle bannercircle-rotate"
                src={bannercircle}
                alt=""
              />
              <img className="service-bg" src={sec1bg} alt="" />
              <div className="Service-box">
                <img src={box1} alt="" />
                <h3>Certified ESDM Expertise</h3>
                <p>
                  Our team comprises certified professionals with extensive
                  experience in implementing the ESDM approach. A certified
                  therapists team specialize in delivering ESDM therapy sessions
                  tailored to your child's developmental requirements. Through
                  play-based, engaging activities, we target specific goals to
                  enhance your child's overall development.
                </p>
              </div>
              <div className="Service-box">
                <img src={box2} alt="" />
                <h3>Comprehensive Assessments</h3>
                <p>
                  We conduct thorough assessments to evaluate your child's
                  strengths and areas needing improvement. These assessments
                  guide our intervention plans, ensuring a personalized approach
                  for each child.
                </p>
              </div>
              <div className="Service-box">
                <img src={box3} alt="" />
                <h3>Evidence-Based Practices</h3>
                <p>
                  We adhere to the highest standards of evidence-based
                  practices, ensuring the effectiveness and success of our
                  interventions.
                </p>
              </div>
            </div>
            <div className="sec2-service">
              <div className="Service-box">
                <img src={box4} alt="" />
                <h3>Individualized Approach</h3>
                <p>
                  We recognize and celebrate the uniqueness of each child,
                  tailoring interventions to their specific needs.
                </p>
              </div>
              <div className="Service-box">
                <img src={box5} alt="" />
                <h3>Collaborative Environment</h3>
                <p>
                  We foster a collaborative environment, encouraging open
                  communication and teamwork between ourselves therapists,
                  parents, and any other professionals involved in the child's
                  care.
                </p>
              </div>
              <div className="Service-box">
                <img src={box6} alt="" />
                <h3>Family-Centred Care</h3>
                <p>
                  We believe in the importance of collaboration with families,
                  valuing their insights and involving them in the intervention
                  process.
                </p>
              </div>
            </div>
            <div style={{ justifyContent: "center" }} className="sec2-service">
              <div className="Service-box">
                <img src={box7} alt="" />
                <h3>Parent Training and Support</h3>
                <p>
                  We believe in empowering parents as partners in their child's
                  growth. Our team offers ESDM parent training sessions and
                  ongoing support to facilitate skill transfer and maximize
                  progress in the home and community environment.
                </p>
              </div>
            </div>
            <p className="sec-solongop">
              At Solongo Early Intervention, we are more than just an early
              intervention provider; we are partners in your child's
              developmental journey. Join us as we create a nurturing and
              supportive environment that empowers children to thrive.
            </p>
          </div>
        </div>
        <div className="sec3" id="about-meet-our-sec">
          <div className="container-1440">
            <div className="sec3-content">
              <div className="sec3-inner-content">
                <h2>meet our team</h2>
                <div className="sec3-Sharon">
                  <div className="">
                    <img src={sec3sharon} alt="" />
                  </div>
                  <div className="sec3 aboutmeet-mg-left">
                    <h3>Sharon Sirige</h3>
                    <ul className="sec3-Certified">
                      <li>Founder of Solongo Early Intervention</li>
                      <li>Certified ESDM Therapist</li>
                      <li>ESDM Parent Coacher</li>
                      <li>Senior Early Intervention Consultant</li>
                      <li>Bachelor of Education</li>
                      <li>Master of Autism( Current)</li>
                    </ul>
                  </div>
                </div>
                <div className="sec3-importance">
                  <p>
                    I'm Sharon Sirige, a Certified ESDM Therapist and ESDM
                    Parent Coach, and a dedicated mother of two boys. Possessing
                    a natural ability to connect with children and an unwavering
                    dedication to assisting parents in their children's
                    developmental journey, I contribute a wealth of expertise to
                    our ESDM Program.
                  </p>
                  <p>
                    As a nurturing mother, I understand the importance of
                    fostering genuine connections with children. Beyond my
                    personal experiences, my professional commitment extends to
                    mentoring and training fellow therapists. My goal is to
                    amplify the positive impact of ESDM therapy through
                    knowledge-sharing and expertise.
                  </p>
                  <p>
                    I emphasize inclusivity, recognizing the pivotal role
                    parents play in their child's development. My approach
                    involves fostering a collaborative relationship where
                    parents actively participate in understanding and supporting
                    their children's journey towards realizing their full
                    potential. Understanding the significance of parent
                    involvement, I provide coaching and guidance not only in
                    ESDM therapy but also in empowering parents to become active
                    participants in their child's progress. Proficient in
                    English, and Mandarin, I ensure effective communication,
                    reaching out to a diverse range of families.
                  </p>
                  <p>
                    My passion lies in collaborating closely with parents,
                    acknowledging their vital role in their child's growth.
                    Through this partnership, I strive to tailor interventions
                    and strategies that resonate with each child's unique needs,
                    ensuring a holistic approach to their development.
                  </p>
                  <p>
                    In every session, I bring my expertise, empathy, and
                    profound understanding of child development to create an
                    enriching environment where children thrive, and families
                    flourish.{" "}
                  </p>
                  <p>
                    I look forward to being a part of your child's developmental
                    success!
                  </p>
                  {/* <p>
                    Understanding the significance of parent involvement, I
                    provide coaching and guidance not only in ESDM therapy but
                    also in empowering parents to become active participants in
                    their child's progress. Proficient in English, Mandarin, I
                    ensure effective communication, reaching out to a diverse
                    range of families
                  </p>
                  <p>
                    My passion lies in collaborating closely with parents,
                    acknowledging their vital role in their child's growth.
                    Through this partnership, I strive to tailor interventions
                    and strategies that resonate with each child's unique needs,
                    ensuring a holistic approach to their development.
                  </p>
                  <p>
                    In every session, I bring my expertise, empathy, and
                    profound understanding of child development to create an
                    enriching environment where children thrive, and families
                    flourish. Together, let's embark on a journey towards
                    unlocking your child's full potential.
                  </p>
                  <p>
                    Feel free to contact me for further information or to
                    schedule a session. I look forward to being a part of your
                    child's developmental success!
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sec3" id="about-meet-our-sec">
          <div className="container-1440">
            <div className="sec3-content About-travis-content">
              <div className="sec3-inner-content ">
                <div className="sec3-Sharon">
                  <div className="">
                    <img src={ABoutourteam} alt="" />
                  </div>
                  <div className="sec3 About-travis aboutmeet-mg-left">
                    <h3>Travis Myors</h3>
                    <ul className="sec3-Certified">
                      <li>Lead Therapist/Clinic Coordinator</li>
                      <li>Advanced-Trained ESDM Therapist</li>
                      <li>Bachelor of Science in Psychology</li>
                      <li>Bachelor of Psychological Science (Honours)</li>
                    </ul>
                  </div>
                </div>
                <div className="sec3-importance">
                  <p>
                    Travis has completed advanced training in ESDM, holds
                    specialized expertise in assisting children with Autism
                    Spectrum Disorder and their families. His empathetic
                    approach is tailored to empower children to achieve their
                    goals and foster meaningful connections. Alongside his
                    therapeutic proficiency, Travis has completed training in
                    community sports coaching and delights in incorporating his
                    passion for music to enhance session engagement.
                  </p>
                  <p>
                    <p>
                      Coming from a town in regional victoria, Travis
                      understands the importance of community. He has much
                      experience supporting families and neurodivergent children
                      as a disability support worker and tutor. As such, he is
                      passionate about collaborating with parents and community
                      members to tailor intervention to each child’s needs.
                    </p>
                  </p>
                  <p>
                    Travis is also actively engaged in psychological studies in
                    a Psychology honours program at the Australian Catholic
                    University. He is especially interested and has been
                    involved in research focusing on motivation and
                    self-determination theory.
                  </p>
                  <p>
                    Outside work hours he enjoys getting out in nature, bike
                    riding, and playing the drums. He is an avid reader and
                    loves to learn.
                  </p>
                  {/* <p>
                  Travis has completed advanced training in ESDM, holds specialized expertise in assisting children with
Autism Spectrum Disorder and their families. His empathetic approach is tailored to empower children
to achieve their goals and foster meaningful connections. Alongside his therapeutic proficiency, Travis
has completed training in community sports coaching and delights in incorporating his passion for
music to enhance session engagement. Additionally, he actively engages in psychological studies at
the university, focusing on motivation and self-determination.
                  </p> */}
                  {/* <p>
                    Understanding the significance of parent involvement, I
                    provide coaching and guidance not only in ESDM therapy but
                    also in empowering parents to become active participants in
                    their child's progress. Proficient in English, Mandarin, I
                    ensure effective communication, reaching out to a diverse
                    range of families
                  </p>
                  <p>
                    My passion lies in collaborating closely with parents,
                    acknowledging their vital role in their child's growth.
                    Through this partnership, I strive to tailor interventions
                    and strategies that resonate with each child's unique needs,
                    ensuring a holistic approach to their development.
                  </p>
                  <p>
                    In every session, I bring my expertise, empathy, and
                    profound understanding of child development to create an
                    enriching environment where children thrive, and families
                    flourish. Together, let's embark on a journey towards
                    unlocking your child's full potential.
                  </p>
                  <p>
                    Feel free to contact me for further information or to
                    schedule a session. I look forward to being a part of your
                    child's developmental success!
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default About;

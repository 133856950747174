import {
  faEnvelope,
  faLocationDot,
  faPhone,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Header from "../Components/Header";
import Bannerbottom from "../Assets/images/AboutBanner.png";
import bannercircle from "../Assets/images/Bannercircle.png";
import sec1bg from "../Assets/images/Sec1bg.png";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAnalytics } from "../Components/useAnalytics";

function Contact2() {
  useAnalytics();

  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   number: "",
  //   message: "",
  // })

  // const handleFormChange = (key, value) => {
  //   setFormData({ ...formData, [key]: value })
  // }

  // const handleFormSubmit = async (e) => {
  //   // e.preventDefault(); // Prevent the default form submission behavior
  //   // console.log("form submission start>>", formData)

  //   // Validate required fields
  //   const requiredFields = ['name', 'email', 'number', 'message'];
  //   const missingFields = requiredFields.filter(field => !formData[field]);

  //   if (missingFields.length > 0) {
  //     // console.error(`Please fill in all required fields.`);
  //     toast.error('Please fill in all required fields.', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });
  //     return;
  //   }

  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailRegex.test(formData.email)) {
  //     // Show an error message or handle the invalid email case
  //     // console.error("Invalid email format");
  //     toast.error('Invalid email format', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });
  //     return;
  //   }

  //   try {
  //     // console.log("url>>>", `${process.env.REACT_APP_BASE_URL}/register`,)
  //     const res = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/contact`,
  //       formData,
  //     )
  //     // console.log("res>>", res)

  //     if (res.status === 200) {
  //       toast.success('Form submitted successfully', {
  //         position: 'top-right',
  //         autoClose: 3000, // Close the toast after 3 seconds
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //       });

  //       setFormData({
  //         name: "",
  //         number: "",
  //         message: "",
  //         email: "",
  //       })
  //     }
  //   }
  //   catch (e) {
  //     // console.error("err>>", e)
  //     if (e.response?.status === 400) {
  //       // console.log(e?.response?.data?.message)
  //       toast.error(e?.response?.data?.message || "Form submission failed. Please try again later.", {
  //         position: 'top-right',
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //       });
  //     }
  //     else {
  //       // console.log("Form submission failed. Please try again later.")
  //       toast.error("Form submission failed. Please try again later.", {
  //         position: 'top-right',
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //       });
  //     }
  //   }

  // }
  return (
    <>
      <div className="contact-page-main-parent">
        <Header />
        <div className="About-banner">
          <img
            // className="bannercircle bannercircle-res bannercircle-rotate"
            className="bannercircle bannercircle-rotate"
            src={bannercircle}
            alt=""
          />
          <div className="container-1440">
            <div className="banner-content">
              <div className="Banner-text">
                <h1>Contact Us</h1>
                <h3 className="contact-main-p">
                  a leading certified Early Start Denver Model (ESDM) Early
                  Intervention provider in Melbourne.
                </h3>
              </div>
              <div className="Banner-pic">
                <img src={sec1bg} alt="" />
              </div>
            </div>
          </div>
        </div>
        <section className="sec1">
          <div className="cloudimg-contact">
            <img className="bannerbottom" src={Bannerbottom} alt="" />
          </div>
        </section>

        <section className="sec2-contact-page">
          <span className="bannercircle-contact-left">
            <img className="bannercircle-rotate" src={bannercircle} alt="" />
          </span>
          <span className="bannercircle-contact-right  bannercircle-contact2-right">
            <img
              className="bannercircle-rotate bannercircle-res"
              src={bannercircle}
              alt=""
            />
          </span>
          <div className="container-1440 contact2-main">
            <h2>Contact Us</h2>
            <div className="contact2-inner-content">
              <p className="contact2-innerp">
                If you're seeking Certified ESDM Early Intervention services in
                Melbourne, contact Solongo Early Intervention. Contact us today
                to schedule a Free Consultation Session or to learn more about
                how we can support your child's developmental journey.
              </p>
              <div className="contact2-form">
                <div className="CertifiedESDM-res">
                  <h3>Address</h3>
                  <div
                    style={{ display: "flex", alignItems: "baseline" }}
                    className=""
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: "5px", color: "#3d3d3d" }}
                      icon={faLocationDot}
                    />
                    <p>
                      Address: 579 High Street, <br /> Kew East VIC 3102{" "}
                    </p>
                  </div>
                </div>
                <div className="CertifiedESDM-res">
                  <h3>Opening Hours</h3>
                  <p>
                    Monday – Friday <br />
                    8:30 AM – 5:30 PM
                  </p>
                  <p style={{ marginBottom: "none" }}>
                    Weekends - Open for Social Group, <br /> Contact Us For More
                    Information{" "}
                  </p>
                  <Link
                    target="blank"
                    to="https://www.victoriapublicholiday.com.au/victoria-public-holidays-2024/"
                  >
                    <p className="public-holiday">Public Holidays</p>
                  </Link>
                </div>
                <div className="CertifiedESDM-res">
                  <h3>Phone</h3>
                  <Link
                    to="telto:+0415 055 666"
                    style={{ display: "flex", alignItems: "baseline" }}
                    className=""
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: "5px", color: "#3d3d3d" }}
                      icon={faMobile}
                    />
                    <p>0415 055 666</p>
                  </Link>
                  <Link
                    to="telto:+03 7044 9755"
                    style={{ display: "flex", alignItems: "baseline" }}
                    className=""
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: "5px", color: "#3d3d3d" }}
                      icon={faPhone}
                    />
                    <p>03 7044 9755</p>
                  </Link>
                  <h3>Email</h3>
                  <Link
                    to="mailto:admin@Solongoearlyintervention.com.au"
                    style={{ display: "flex", alignItems: "baseline" }}
                    className=""
                  >
                    <FontAwesomeIcon
                      style={{
                        paddingTop: "2px",
                        marginRight: "5px",
                        color: "#3d3d3d",
                      }}
                      icon={faEnvelope}
                    />
                    <p>admin@solongoearlyintervention.com.au</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <span className="bannercircle-contact-left  bannercircle-contact2-left2">
            <img className="bannercircle-rotate" src={bannercircle} alt="" />
          </span>
          <div className="Contact2-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.5709992256034!2d145.04564797676377!3d-37.8000924332583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad643e1edb6a66f%3A0xc6e9b193b832576d!2s579%20High%20St%2C%20Kew%20East%20VIC%203102%2C%20Australia!5e0!3m2!1sen!2s!4v1708939801315!5m2!1sen!2s"
              frameBorder="0"
              scrolling="no"
              style={{ width: "100%", height: "300px" }}
            ></iframe>
          </div>
          <div></div>
        </section>
        <Footer />

        <ToastContainer />
      </div>
    </>
  );
}

export default Contact2;

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import headerlogo from "../Assets/images/headerlogo.png";
import headerhover from "../Assets/images/menuhower.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  faCircleXmark,
  faEnvelope,
  faLocationDot,
  faTimes,
  faX,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();

  const [formData, setFormData] = useState({
    full_name: "",
    date_of_birth: "",
    start_date: "",
    parent_name: "",
    parent_mobile: "",
    parent_email: "",
    other_info: ""
  })
  // console.log("formData>>", formData)


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormChange = (key, value) => {
    setFormData({ ...formData, [key]: value })
  }

  const handleFormSubmit = async () => {
    // e.preventDefault(); // Prevent the default form submission behavior
    // console.log("form submission start>>", formData)

    // Validate required fields
    const requiredFields = ['full_name', 'start_date', 'parent_mobile', 'date_of_birth', 'parent_name', 'parent_email'];
    const missingFields = requiredFields.filter(field => !formData[field]);

    if (missingFields.length > 0) {
      console.error(`Please fill in all required fields.`);
      toast.error('Please fill in all required fields.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.parent_email)) {
      // Show an error message or handle the invalid email case
      console.error("Invalid email format");
      toast.error('Invalid email format', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    try {
      // console.log("url>>>", `${process.env.REACT_APP_BASE_URL}/register`,)
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/register`,
        formData,
      )
      // console.log("res>>", res)

      if (res.status === 200) {
        toast.success('Form submitted successfully', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        setFormData({
          full_name: "",
          date_of_birth: "",
          start_date: "",
          parent_name: "",
          parent_mobile: "",
          parent_email: "",
          other_info: ""
        })

        setTimeout(() => {
          handleClose();
        }, 1500); // Adjust the delay time as needed
      }


    }
    catch (e) {
      // console.error("err>>", e)
      if (e.response?.status === 400) {
        // console.log(e?.response?.data?.message)
        toast.error(e?.response?.data?.message || "Form submission failed. Please try again later.", {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      else {
        // console.log("Form submission failed. Please try again later.")
        toast.error("Form submission failed. Please try again later.", {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        handleClose()
      }
    }


  }

  const isPageActive = (path) => {
    // Check if the current path matches the provided path
    return location.pathname === path;
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  };
  ////////////////SMALL SCREEN HEADER///////////////
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  ////////////////SMALL SCREEN HEADER///////////////

  return (
    <>
      {/* //////////////////SMALL SCREEN HEADER//////////////////// */}
      <div className={isOpen ?  'responsive-header home-banner-header-parent-res' : ''} >
        <div className='small-screen-header relative'>
        <FontAwesomeIcon
            className="text-[#2B303C] small-screen-toggle font-size-22px font-Sofia-Bold"
            icon={isOpen ? faTimes : faBars}
            style={{
              transition: 'transform 0.3s ease-in-out', // Apply smooth transition to transform property
              transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)' // Rotate icon based on isOpen state
            }}
            onClick={() => setIsOpen(!isOpen)}
          />
          
          <div className='small-screen-header-logo'>
            <Link to="/">
              <img src={headerlogo} alt="" />
            </Link>
          </div>
          <div onClick={handleShow}>
            <Link style={{textDecoration:"none"}} to="">
              <span className="header-register">REGISTER</span>
            </Link>
          </div>
        </div>
        <header className="header header-res">
          <ul>
            <li className={`hovermenus home-head-cloud-parent ${isPageActive("/") && "active"}`}>
              <Link to="/">
                <img src={headerhover} alt="" /> <span>HOME</span>{" "}
              </Link>
            </li>
            <li
              className={`hovermenus home-head-cloud-parent ${isPageActive("/about") && "active"}`}
            >
              <Link to="/about">
                <img src={headerhover} alt="" />
                <span>ABOUT US</span>
              </Link>
            </li>
            <li
              className={`hovermenus home-head-cloud-parent ${isPageActive("/therapy") && "active"}`}
            >
              <Link to="/therapy">
                <img className="d-fferent-clouds" src={headerhover} alt="" />
                <span>ESDM THERAPY</span>
              </Link>
            </li>
            <li
              className={`hovermenus home-head-cloud-parent ${isPageActive("/Program") && "active"}`}
            >
              <Link to="/program">
                <img className="d-fferent-clouds" src={headerhover} alt="" />
                <span>OUR PROGRAMS</span>
              </Link>
            </li>
            <li
              className={`hovermenus home-head-cloud-parent ${isPageActive("/Contact2") && "active"}`}
            >
              <Link to="/contact">
                <img src={headerhover} alt="" />
                <span>CONTACT</span>
              </Link>
            </li>
          </ul>
        </header>
      </div>
      {/* //////////////////SMALL SCREEN HEADER//////////////////// */}
      <div className="Header-Main">
        <div className="container-1440">
          <header className="header">
            <ul>
              <li className={`hovermenus home-head-cloud-parent ${isPageActive("/") && "active"}`}>
                <Link to="/">
                  <img src={headerhover} alt="" /> <span>HOME</span>{" "}
                </Link>
              </li>
              <li
                className={`hovermenus ${isPageActive("/about") && "active"}`}
              >
                <Link onClick={scrollTop} to="/about">
                  <img src={headerhover} alt="" />
                  <span>ABOUT US</span>
                </Link>
              </li>
              <li
                className={`hovermenus ${isPageActive("/therapy") && "active"}`}
              >
                <Link to="/therapy">
                  <img className="d-fferent-clouds" src={headerhover} alt="" />
                  <span>ESDM THERAPY</span>
                </Link>
              </li>
              <li className="header-logo-li">
                <Link to="/">
                  <img src={headerlogo} alt="" />
                </Link>
              </li>
              <li
                className={`hovermenus ${isPageActive("/program") && "active"}`}
              >
                <Link to="/program">
                  <img className="d-fferent-clouds" src={headerhover} alt="" />
                  <span>OUR PROGRAMS</span>
                </Link>
              </li>
              <li
                className={`hovermenus ${isPageActive("/Contact") && "active"}`}
              >
                <Link to="/contact">
                  <img src={headerhover} alt="" />
                  <span>CONTACT</span>
                </Link>
              </li>
              <li onClick={handleShow}>
                <Link to="">
                  <span className="header-register">REGISTER</span>
                </Link>
              </li>
            </ul>
          </header>
        </div>
      </div>
      <Modal
        className="Register-modal"
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="Therapy-Registration-Modal">
          <div onClick={handleClose} className="modal-xmark">
            <FontAwesomeIcon icon={faCircleXmark} />
          </div>
          <h2>ESDM Therapy Registration</h2>
          <p>
            We are thrilled to embark on this journey with you and your child.
            Our Early Start Denver Model
            (ESDM) therapy is designed to provide individualized support,
            fostering your child's development
            and enhancing their social and communication skills.
          </p>
          <p>
            To get started, please fill out the registration form below. Your
            input will help us tailor our approach
            to meet your child's unique needs effectively. We look forward to
            partnering with you on this
            transformative path towards growth and progress.
          </p>
          <h3>
            Thank you for choosing us to be a part of your child's developmental
            journey!
          </h3>
        </div>
        <iframe
          id='JotFormIFrame-241898278156876'
          title='Appointment Request Form'
          onLoad={() => window.parent.scrollTo(0, 0)}
          allowTransparency='true'
          allow='geolocation; microphone; camera; fullscreen'
          src='https://form.jotform.com/241898278156876'
          frameBorder='0'
          style={{ minWidth: '100%', maxWidth: '100%', height: '900px', border: 'none' }}
          scrolling='no'
        ></iframe>

        <ToastContainer />
      </Modal>



      {/* ... (rest of your code) */}
    </>
  );
};

export default Header;

import React from "react";
import footerleft from "../Assets/images/Footer-left.png";
import {
  faPhone,faMobile
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <div className="footer-top">
        <img src={footerleft} alt="" />
        <div className="footer-map">
          <h2>Contact us</h2>
          <p>
          If you're seeking Certified ESDM Early Intervention services in Melbourne, <br />contact Solongo Early Intervention. Contact us today to schedule a Free  <br/> Consultation Session or to learn more about how we can support your  <br/> child's developmental journey.
          </p>
          <div className="d-flex align-items-center mobile-icon justify-content-center mt-3">
            <FontAwesomeIcon
            className="mobile-svg"
              style={{ marginRight: "15px" }}
              icon={faMobile}
            />
            <p className="p-0 mb-0"><Link className="text-decoration-none text-black" to="tel:0415 055666">0415 055 666</Link></p>
          </div>
          <div className="d-flex align-items-center justify-content-center mb-3">
            <FontAwesomeIcon
            className="mobile-svg"
              style={{ marginRight: "15px" }}
              icon={faPhone}
            />
            <p className="p-0 mb-0"><Link className="text-decoration-none text-black" to="tel:03 7044 9755">03 7044 9755</Link></p>
          </div>
          <h3>Location</h3>
          <div className="map-contact-parent">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.5709992256034!2d145.04564797676377!3d-37.8000924332583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad643e1edb6a66f%3A0xc6e9b193b832576d!2s579%20High%20St%2C%20Kew%20East%20VIC%203102%2C%20Australia!5e0!3m2!1sen!2s!4v1708939801315!5m2!1sen!2s"
                  frameBorder="0"
                  scrolling="no"
                  style={{ width: "600px", height: "100%" }}
                ></iframe>
                <a
                  href="https://blooketjoin.org/blooket-host/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  blooket host
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

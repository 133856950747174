import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import HomeEsdmShape from "../Assets/images/home-esdm-shape.png";
import HomeEsdmShape2 from "../Assets/images/children-sharp-img.png";
import HomeEsdmKid from "../Assets/images/home-esdm-right.png";
import homeOrangeEdsm from "../Assets/images/home-sec2-orange-img.png";

const HomeESDM = () => {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  };
  return (
    <section className="home-esdm-sec">
      <div className="content-parent-homeESDM position-relative">
        <div className="position-absolute HomeEsdmShape-parent">
          <img className="HomeEsdmShape" src={homeOrangeEdsm} alt="" />
          <img className="HomeEsdmShape2" src={HomeEsdmShape2} alt="" />
        </div>
        <div className="right-esdm-home-img-parent">
          <img src={HomeEsdmKid} alt="" />
        </div>
        <div className="container-1440 position-relative z-20">
          <div className="row">
            <div className="col-6 maincol-content-homesdm">
              <div className="col-content-homesdm">
                <h2 className="home-esdm-heading">ABOUT ESDM THERAPY</h2>
                <div className="sub-content-home-esdm">
                  <h5 className="sub-heading-esdm-home">
                    What is ESDM Therapy?
                  </h5>
                  <p className="sub-para-esdm-home">
                    ESDM (Early Start Denver Model) therapy stands as an
                    intensive, comprehensive play-based and evidence-based early
                    intervention specifically designed for toddlers and young
                    children diagnosed with or at risk of autism spectrum
                    disorder (ASD) and developmental delays.
                  </p>
                </div>
                <div className="sub-content-home-esdm">
                  <h5 className="sub-heading-esdm-home">Why Choose ESDM?</h5>
                  <p className="sub-para-esdm-home">
                    ESDM therapy stands out among various early intervention
                    approaches due to its comprehensive, play-based model that
                    embeds learning within engaging and interactive activities.
                  </p>
                </div>
                <div className="sub-content-home-esdm">
                  <h5 className="sub-heading-esdm-home">
                    Benefits of ESDM Therapy for Children's Development:
                  </h5>
                  <p className="sub-para-esdm-home">
                    Children undergoing ESDM therapy experience a multitude of
                    benefits:
                  </p>
                  <div className="sub-content-esdm-bullet-points">
                    <div className="row">
                      <li className="col-6">Enhanced Social Skills</li>
                      <li className="col-6">Behavioural Adaptability</li>
                    </div>
                    <div className="row">
                      <li className="col-6">Improved Communication</li>
                      <li className="col-6">
                        Positive Developmental Trajectory
                      </li>
                    </div>
                    <div className="row">
                      <li className="col-6">Cognitive Advancements</li>
                    </div>
                  </div>
                </div>
                <div className="phome-esdm-btn-parent">
                  <Link onClick={scrollTop} to="/Therapy" className="home-about-btn yellow-btn-hover">
                    LEARN MORE
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeESDM;

// GTM.js
import { useEffect } from "react";

const GTM_ID = "GTM-N27L6CZG"; // Replace with your GTM ID

const GTM = () => {
  useEffect(() => {
    if (!document.getElementById("gtm-script")) {
      const script = document.createElement("script");
      script.id = "gtm-script";
      script.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`;
      script.async = true;
      document.head.appendChild(script);

      const noScript = document.createElement("noscript");
      noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noScript);
    }
  }, []);

  return null;
};

export default GTM;

import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const HomeRaise = () => {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    })
  }
  return (
    <section className="home-raise-sec">

      <div className="container-1440">
        <div className="content-parent-raise d-flex flex-column align-items-center text-center">
          <h2 className="heading-home-raise">
          "Transforming Challenges into Triumphs.Nurturing Every Spark, Embracing Every Step. Elevate Your Child's Development with Our Specialized ESDM Early Intervention Services. – Guiding Every Child's Unique Journey to Success!"
          </h2>
          <div className="pull-pulse home-raise-btn-parent">
            <Link onClick={scrollTop} to="/Contact2" className="home-about-btn ornge-btn-hover">
              CONTACT US
              <FontAwesomeIcon icon={faArrowRightLong} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeRaise;

import React from "react";
import MeetTeamImg from "../Assets/images/meet-our-home-img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import sec1bg from "../Assets/images/Sec1bg.png";

const HomeMeetTeam = () => {
  return (
    <section className="home-meet-sec">
      <div className="container-1440">
        <div className="content-parent-home-meet">
          <div className="row align-items-end home-meet-sec-row">
            <img className="Meet-our-team-bg" src={sec1bg} alt="" />
            <div className="col-3 sharon-res-first sharon-res">
              <div className="left-img-team-meet">
                <img src={MeetTeamImg} alt="" />
              </div>
            </div>
            <div className="col-9 sharon-res">
              <h2 className="heading-home-meet-team text-start">
                Meet Our Team
              </h2>
              <div className="right-content-home-meet d-flex flex-column gap-4">
                <div>
                  <h4 className="right-home-meet-heading mb-0">
                    Sharon Sirige
                  </h4>
                  <p className="right-home-meet-para fw-medium mb-0">
                    Founder of Solongo Early Intervention ESDM Program,
                    Certified ESDM Therapist, ESDM
                    Parent Coacher, Senior Early Intervention Consultant,
                    Bachelor of Education, Master of Autism( Current)
                  </p>
                </div>
                <p className="right-home-meet-para fw-medium mb-0">
                  I am Sharon Sirige, a Certified ESDM Therapist/ESDM Parent
                  Coacher and a devoted mother of two boys. With a natural
                  affinity for connecting with children and an unwavering
                  commitment to supporting parents on their developmental
                  journey, I bring a wealth of expertise to our ESDM Program.
                </p>
                <div className="home-meet-btn-parent d-flex">
                  <Link to="/About#about-meet-our-sec" className="pull-pulse home-about-btn yellow-btn-hover">
                    LEARN MORE
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </Link>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeMeetTeam;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/Assets/css/style.css";
import "../src/Assets/css/responsive.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Therapy from "./Pages/Therapy";
import Program from "./Pages/Program";
// import Contact from "./Pages/Contact3";
import Contact from "./Pages/Contact";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Therapy" element={<Therapy />} />
        <Route path="/Program" element={<Program />} />
        {/* <Route path="/Contact" element={<Contact />} /> */}
        <Route path="/Contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import bannercircle from "../Assets/images/Bannercircle.png";
import Bannerbottom from "../Assets/images/AboutBanner.png";
import sec1bg from "../Assets/images/Sec1bg.png";
import Header from "../Components/Header";
import ProgramImg1 from "../Assets/images/ProImg1.png";
import ProgramImg2 from "../Assets/images/ProImg2.png";
import ProgramImg3 from "../Assets/images/ProImg3.png";
import ProgramImg4 from "../Assets/images/ProImg4.png";
import ProgramImg5 from "../Assets/images/ProImg5.png";
import ProgramImg6 from "../Assets/images/ProImg6.png";
import ProgramImg7 from "../Assets/images/ProImg7.png";
import ProgramImg8 from "../Assets/images/ProImg8.png";
import ProgramImg9 from "../Assets/images/ProImg9.png";
import ProgramImg10 from "../Assets/images/ProImg10.png";
import ProgramImg11 from "../Assets/images/ProImg11.png";
import ProgramImg12 from "../Assets/images/ProImg12.png";
import ProgramImg13 from "../Assets/images/ProImg13.png";
// import ProgramImg14 from "../Assets/images/ProImg14.png";
import ProgramImg15 from "../Assets/images/ProImg15.png";
import ProgramImg16 from "../Assets/images/ProImg16.png";
import ProgramImg14 from "../Assets/images/ProImg17n.png";
import Footer from "../Components/Footer";
import { useAnalytics } from "../Components/useAnalytics";

const Program = () => {
  useAnalytics();

  return (
    <div className="Main-Therapy">
      <Header />
      <div className="About-banner program-banner">
        <img
          className="bannercircle bannercircle-rotate"
          src={bannercircle}
          alt=""
        />
        <div className="container-1440">
          <div className="banner-content">
            <div className="Banner-text">
              <h1>Our Programs</h1>
              <h3>
                A LEADING CERTIFIED EARLY START DENVER MODEL (ESDM) EARLY
                <br />
                INTERVENTION PROVIDER IN MELBOURNE.
              </h3>
            </div>
          </div>
          <div className="Banner-pic">
            <img src={sec1bg} alt="" />
          </div>
        </div>
      </div>
      <div className="sec1 Therapysec1 program-sec">
        <div className="cloudimg">
          {" "}
          <img className="bannerbottom" src={Bannerbottom} alt="" />{" "}
        </div>
        <img
          className="sec1circle therapyCircle1 bannercircle-rotate"
          src={bannercircle}
          alt=""
        />
        <img
          className="program-sec-one-top-left-circ bannercircle-rotate"
          src={bannercircle}
          alt=""
        />
        <img
          className="program-sec-one-center-right-circ bannercircle-rotate"
          src={bannercircle}
          alt=""
        />
        <img className="sec1bg-program" src={sec1bg} alt="" />

        <div className="container-1440">
          <div className="row">
            <div className="col-lg-6">
              <div className="sec1-text Protext111 Protext1">
                <h5>
                  Intensive Early Start Denver Model (ESDM) Program Options:
                </h5>
                <h6>Intensive Clinical Program</h6>
                <p>
                  Our Clinic Intensive ESDM Therapy Program is a transformative
                  and <br />
                  comprehensive intervention designed to empower children on the{" "}
                  <br />
                  autism spectrum during their crucial developmental stages. At
                  Solongo <br />
                  Early Intervention, we are committed to providing a nurturing
                  and <br />
                  immersive learning environment that goes beyond traditional
                  approaches.
                </p>
                <ul className="Interventions-res">
                  <li>
                    <b>Structured Learning Environment: </b> Our clinic is
                    tailored to mirror the <br />
                    structure of an early learning centre, creating a purposeful
                    and organized <br />
                    space conducive to effective ESDM therapy.
                  </li>
                  <li>
                    <b>Group and Snack Times: </b> Incorporated group and snack
                    times are <br />
                    strategically integrated into the program to promote
                    personal <br />
                    independence and enhance social skills among peers. These
                    activities <br />
                    foster a sense of community and engagement.
                    <br />
                  </li>
                  <li>
                    <b>Comprehensive and Immersive Learning: </b> Our program is
                    meticulously <br />
                    designed to offer a comprehensive and immersive learning
                    experience. <br />
                    We understand the importance of the initial phase of
                    intervention, and <br />
                    our approach is finely tuned to address the unique needs of
                    each child.
                  </li>
                  <li>
                    <b>Expert-Led Interventions: </b> Our team of Certified ESDM
                    Therapists, led by <br />
                    professionals with a profound understanding of child
                    development, <br />
                    delivers expert-led interventions. We are dedicated to
                    optimizing your <br />
                    child's potential through evidence-based practices.
                  </li>
                  <li>
                    <b>Individualized Approach: </b> Recognizing the uniqueness
                    of each child, our <br />
                    program employs an individualized approach. Tailored
                    interventions <br />
                    and strategies ensure that we meet the specific needs of
                    your child, <br />
                    fostering a holistic developmental journey.
                  </li>
                  <li>
                    <b>Parent Involvement: </b> We believe in the power of
                    collaboration. Our <br />
                    program emphasizes the involvement of parents in their
                    child's progress. <br />
                    We provide coaching and guidance to empower parents as
                    active <br />
                    participants in their child's developmental success.
                  </li>
                </ul>
                <h5>
                  Language Accessibility: Our therapists can deliver ESDM
                  therapy in <br />
                  multiple languages, including English, and Mandarin, ensuring
                  effective <br />
                  communication and support for families from diverse
                  backgrounds.
                </h5>
              </div>
            </div>
            <div className="col-lg-6">
              <img className="ClinicalImg1" src={ProgramImg1} alt="" />
              <img className="ClinicalImg2" src={ProgramImg2} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="sec1 sec3 ProSec2 program-sec program-sec-mg-0 program-padding-sec program-padding-top-bottom">
        <div className="cloudimg">
          <div className="container-1440">
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="bannercircle-rotate circle1-sec2"
                  src={bannercircle}
                  alt=""
                />
                <img
                  className="program-left-align-bg-img"
                  src={sec1bg}
                  alt=""
                />
                <img className="KinderImg1" src={ProgramImg3} alt="" />
                <img className="KinderImg2" src={ProgramImg4} alt="" />
                <img className="KinderImg3" src={ProgramImg5} alt="" />
              </div>
              <div className="col-lg-6">
                <div className="sec1-text Protext2 Interventions-res Protext222">
                  <h6>
                    Intensive Home and <br className="d-block" />
                    Kinder Program
                  </h6>
                  <p>
                    A specialized and immersive intervention designed to bring
                    the transformative <br />
                    benefits of Early Start Denver Model (ESDM) therapy directly
                    to the comfort of <br />
                    your home and kindergarten environment. At Solongo Early
                    Intervention, we <br />
                    believe in providing personalized and effective solutions to
                    meet the unique <br />
                    needs of every child.
                  </p>
                  <ul>
                    <li>
                      <b>Tailored Home Interventions: </b> Our experienced team
                      of Certified ESDM <br />
                      Therapists brings the program directly to your home,
                      creating a familiar <br />
                      and comfortable setting for your child. We tailor
                      interventions to address <br />
                      the specific needs of your child within the home
                      environment.
                    </li>
                    <li>
                      <b>Kindergarten Integration: </b> Recognizing the
                      importance of a child's early <br />
                      learning experience, our program seamlessly integrates
                      into the kindergarten <br />
                      setting. This allows for consistent and comprehensive
                      intervention, ensuring <br />
                      your child's developmental progress extends beyond the
                      home.
                    </li>
                    <li>
                      <b>Holistic Developmental Focus: </b> Our Intensive Home
                      and Kinder Program <br />
                      places a strong emphasis on holistic development. We
                      target crucial areas <br />
                      such as communication, social interaction, play, and
                      adaptive behaviours, <br />
                      fostering growth in all aspects of your child's
                      development.
                    </li>
                  </ul>
                  <h3>Individualized Approach: </h3> <br />
                  <p>
                    Understanding that each child is unique, we adopt an
                    individualized approach. <br />
                    Tailored interventions and strategies are implemented to
                    suit your child's <br />
                    specific strengths, challenges, and learning styles.
                  </p>
                  <h3>
                    Parent and Kindergarden/School Educator Collaboration:{" "}
                  </h3>{" "}
                  <br />
                  <p>
                    We believe in collaborative partnerships. Our program
                    encourages active <br />
                    involvement from both parents and kindergarten and school
                    educators.
                  </p>
                  <h3>Flexible Scheduling: </h3> <br />
                  <p>
                    We understand the demands of daily life. Our program offers
                    flexibility in <br />
                    scheduling to accommodate the needs of your family and the
                    kindergarten <br />
                    environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sec1 sec3 Therapysec1 program-sec program-sec-mg-0 program-padding-sec">
        <div className="container-1440">
          <img
            className="program-sec-one-top-left-circ bannercircle-rotate circle2-sec3"
            src={bannercircle}
            alt=""
          />
          <img
            className="bannercircle-rotate circle1-sec3"
            src={bannercircle}
            alt=""
          />
          <div className="row">
            <div className="col-lg-6">
              <img className="program-right-align-bg-img" src={sec1bg} alt="" />
              <div className="sec1-text Protext1 Protext3 CasualProgram-res Interventions-res">
                <h6>Casual Program</h6>
                <p>
                  Discover our Casual Program at Solongo Early Intervention,
                  offering flexible <br />
                  and supportive intervention options for families seeking a
                  more adaptable <br />
                  approach to Early Start Denver Model (ESDM) therapy. We
                  understand that <br />
                  each child is unique, and our Casual Program is designed to
                  provide <br />
                  personalized solutions to fit the diverse needs of families.
                </p>
                <h3>Flexible Session Options :</h3> <br />
                <p>
                  Our Casual Program allows families to choose sessions based on
                  their <br />
                  schedule and needs. Whether you require occasional support or
                  a more <br />
                  flexible intervention plan, our program caters to your
                  preferences.
                </p>
                <h3>Tailored Interventions: </h3> <br />
                <p>
                  Just like our comprehensive programs, our Casual Program
                  focuses on <br />
                  individualized interventions. We work closely with you to
                  understand your <br />
                  child's specific strengths, challenges, and developmental
                  goals.
                </p>
                <h3>Experienced Therapists: </h3> <br />
                <p>
                  Benefit from the expertise of our Certified ESDM Therapists.
                  Our team is <br />
                  dedicated to providing quality interventions that support your
                  child's <br />
                  development in crucial areas such as communication, social
                  interaction, <br />
                  play, and adaptive behaviours.
                </p>
                <h3>Accessible Language Support: </h3> <br />
                <p>
                  Our therapists are proficient in English, and Mandarin,
                  ensuring effective <br />
                  communication and support for families from diverse linguistic
                  backgrounds.
                </p>
                <h3>Parental Involvement: </h3> <br />
                <p>
                  Emphasizing the importance of family involvement, our Casual
                  Program <br />
                  encourages parents to actively participate in the intervention
                  process. <br />
                  We provide guidance and support to empower you as an integral
                  part <br />
                  of your child's developmental journey.
                </p>
                <h3>Affordable and Transparent Pricing:</h3> <br />
                <p>
                  We believe in transparency. Our Casual Program offers
                  affordable <br />
                  pricing with clear and straightforward fee structures,
                  ensuring families <br />
                  can access the support they need without financial ambiguity.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img className="CasualImg1" src={ProgramImg6} alt="" />
              <img className="CasualImg2" src={ProgramImg7} alt="" />
              <img className="CasualImg3" src={ProgramImg8} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="sec1 sec3 ProSec2 ProSec4 program-sec program-sec-mg-0 program-padding-sec">
        <div className="cloudimg">
          <div className="container-1440">
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="bannercircle-rotate circle1-sec2"
                  src={bannercircle}
                  alt=""
                />
                <img
                  className="program-left-align-bg-img"
                  src={sec1bg}
                  alt=""
                />
                <img className="KinderImg1" src={ProgramImg9} alt="" />
                <img className="KinderImg2" src={ProgramImg10} alt="" />
                <img className="KinderImg3" src={ProgramImg11} alt="" />
              </div>
              <div className="col-lg-6">
                <div className="sec1-text JuniorSocial-res Interventions-res Protext2">
                  <h6>
                    Junior Social Group <br />
                    Program
                  </h6>
                  <p>
                    Introducing our Junior Social Group Program at Solongo Early
                    Intervention, a <br />
                    vibrant and engaging initiative crafted to nurture social
                    skills, foster friendships, <br />
                    and create a supportive community for young learners. This
                    program is <br />
                    designed to provide children with a positive and interactive
                    environment <br />
                    where they can develop crucial social competencies while
                    having fun.
                  </p>
                  <h3>Age-Appropriate Activities:(3-5 yeras old)</h3> <br />
                  <p>
                    Our program features age-specific activities tailored to the
                    developmental <br />
                    needs and interests of young learners. From creative play to
                    collaborative <br />
                    games, each session is thoughtfully planned to enhance
                    social interactions.
                  </p>
                  <h3>Facilitated by Certified ESDM Therapists:</h3> <br />
                  <p>
                    Lead by our team of experienced and Certified ESDM
                    Therapists, the Junior <br />
                    Social Group Program ensures that your child receives
                    professional guidance <br />
                    in a supportive and inclusive setting.
                  </p>
                  <h3>Promoting Communication and Collaboration:</h3> <br />
                  <p>
                    We focus on activities that encourage effective
                    communication, teamwork, <br />
                    and shared experiences. Through interactive play and guided
                    exercises, <br />
                    children develop vital social skills naturally and
                    enjoyably.
                  </p>
                  <h3>Small Group Setting:</h3> <br />
                  <p>
                    Our small group setting fosters a sense of community and
                    camaraderie <br />
                    among participants. This enables each child to actively
                    engage with peers, <br />
                    building meaningful connections and friendships.
                  </p>
                  <h3>Inclusive Environment:</h3> <br />
                  <p>
                    We celebrate diversity and create an inclusive space where
                    children from <br />
                    various backgrounds feel welcome. Our program promotes
                    understanding, <br />
                    empathy, and a positive attitude towards differences.
                  </p>
                  <h3>Parent Involvement and Guidance:</h3> <br />
                  <p>
                    Recognizing the significance of parental involvement, our
                    Junior Social Group <br />
                    Program encourages parents to participate in select
                    sessions. This involvement <br />
                    provides an opportunity for parents to learn strategies that
                    can be reinforced <br />
                    at home, enhancing the continuity of social skill
                    development.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sec1 sec3 Therapysec1 ProSec5 program-sec program-sec-mg-0 program-padding-sec">
        <div className="container-1440">
          <div className="row">
            <div className="col-lg-6">
              <img
                className="bannercircle-rotate circle1-sec3"
                src={bannercircle}
                alt=""
              />
              <div className="sec1-text Protext1 ESDMParent-res Protext3 Interventions-res Protext5">
                <h6 style={{ color: "#95C93D" }}>
                  ESDM Parent Coaching <br />
                  Program
                </h6>
                <p>
                  Solongo Early Intervention's ESDM Parent Coaching Program is a{" "}
                  <br />
                  comprehensive and personalized support system designed to
                  empower <br />
                  parents in fostering their child's development. Whether in
                  person at our <br />
                  clinic or via Zoom, our program offers a unique opportunity
                  for parents <br />
                  to actively participate in their child's Early Start Denver
                  Model (ESDM) <br />
                  therapy journey.
                </p>
                <h3>In-Person Clinic-Based Sessions:</h3> <br />
                <p>
                  Our in-person clinic-based sessions provide a comfortable and
                  supportive <br />
                  environment where parents can engage directly with our
                  Certified ESDM <br />
                  Therapists. These sessions are tailored to address the
                  specific needs of <br />
                  your child, allowing you to observe and actively participate
                  in ESDM <br />
                  therapy sessions. We focus on equipping parents with the
                  knowledge <br />
                  and skills to create a positive and enriching developmental
                  environment at home.
                </p>
                <h3>Virtual Sessions via Zoom:</h3> <br />
                <p>
                  For added convenience, we also offer virtual sessions via
                  Zoom, allowing <br />
                  parents to participate from the comfort of their homes.
                  Through this <br />
                  platform, you can connect with our experienced ESDM
                  therapists, receive <br />
                  guidance on implementing strategies, and gain insights into
                  your child's progress. Zoom sessions offer flexibility and
                  accessibility without compromising the quality of support and
                  coaching.
                </p>
                <h4>Program Highlights</h4>
                <h3>Personalized Guidance:</h3> <br />
                <p>
                  Our Certified ESDM Therapists provide personalized guidance,
                  addressing <br />
                  your child's unique strengths and challenges.
                </p>
                <h3>Skill Transfer: </h3> <br />
                <p>
                  Learn practical strategies and techniques to extend ESDM
                  principles into <br />
                  your daily interactions with your child.
                </p>
                <h3>Progress Updates: </h3> <br />
                <p>
                  Stay informed about your child's progress during therapy
                  sessions and gain <br />a deeper understanding of the ESDM
                  approach.
                </p>
                <h3>Q&A Sessions: </h3> <br />
                <p>
                  Engage in interactive Q&A sessions to clarify doubts, share
                  experiences, and <br />
                  collaborate with other parents.
                </p>
                <h3>Flexible Scheduling:</h3> <br />
                <p>
                  Tailor the coaching program to fit your schedule, ensuring
                  that it aligns with <br />
                  your daily routine.
                </p>
                <h4>How to Enroll</h4>
                <p>
                  Enrolling in the ESDM Parent Coaching Program is simple.
                  Contact us to express <br />
                  your interest, and our team will guide you through the
                  enrollment process. <br />
                  Whether you choose in-person sessions or virtual coaching via
                  Zoom, Solongo <br />
                  Early Intervention is committed to supporting you on your
                  journey towards <br />
                  unlocking your child's full potential.
                </p>
                <p>
                  Empower yourself as a parent, and let's work together to
                  create a nurturing <br />
                  environment where your child can thrive.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                className="sec1bg-therapy sec1bg-program ProSec3bg"
                src={sec1bg}
                alt=""
              />
              <img className="EnrollImg1" src={ProgramImg12} alt="" />
              <img className="EnrollImg2" src={ProgramImg13} alt="" />
              <img className="EnrollImg3" src={ProgramImg14} alt="" />
              <img className="EnrollImg4" src={ProgramImg15} alt="" />
              <img className="EnrollImg5" src={ProgramImg16} alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Program;

import {
  faEnvelope,
  faLocationDot,
  faPhone,
  faMobile
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons"; // Import FaTimes icon from @fortawesome/free-solid-svg-icons
import React, { useState } from "react";
import footcamera from "../Assets/images/footer-camera.png";
import facebook from "../Assets/images/Icon awesome-facebook.png";
import ChineseLogo from "../Assets/images/ChineseLogo.png";
import WeChat from "../Assets/images/wechat.png";
import Contact from "./Contact";
import { Link } from "react-router-dom";
import wechatscan from '../Assets/images/Solongo-Early-Intervention-Wechat.jpeg'

const Footer = () => {
  const [modal, modalOpen] = useState(false)
  const openModal = () => {
    modalOpen(true)
  }
  const closeModal = () => {
    modalOpen(false)
  }
  
  return (
    <>
      <div className="footer-and-contact-parent">
        <Contact />
        <footer className="footer">
          <div className="Footer-content">
            <div className="footer-left-text">
              <h4>quick contact</h4>
              <ul>
                <li><Link to='tel:+0415 055666'>
                  <FontAwesomeIcon
                    style={{ marginRight: "15px" }}
                    icon={faMobile}
                  />
                  0415 055 666{" "}
                </Link>
                </li>
                <li> <Link to='tel:+03 7044 9755'>
                  <FontAwesomeIcon
                    style={{ marginRight: "15px" }}
                    icon={faPhone}
                  />
                  03 7044 9755{" "}
                </Link>
                </li>
                <li>
                  <Link to='mailto:Contact@Yourdomain.Com'>
                    <FontAwesomeIcon
                      style={{ marginRight: "15px" }}
                      icon={faEnvelope}
                    />
                    admin@solongoearlyintervention.com.au
                  </Link>
                </li>
                <li>
                  <Link>
                    <FontAwesomeIcon
                      style={{ marginRight: "15px" }}
                      icon={faLocationDot}
                    />
                    579 High Street,
                    Kew East VIC 3102, Australia
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-right-text">
              <h4 className="social-link-head2">social links</h4>
              <div style={{ margin: "20px 0" }}>
                <Link target="blank" to='https://www.facebook.com/profile.php?id=61555756501076&mibextid=LQQJ4d'><img src={facebook} alt="" /></Link>
                <Link target="blank" to='https://www.xiaohongshu.com/user/profile/5c5669e50000000012033e14'><img src={ChineseLogo} alt="" /></Link>
                {/* <Link target="blank" to="https://drive.google.com/file/d/199uqs2nRMllrWcXLkzQGxKMk359otqGn/view?usp=sharing"><img  src={WeChat} alt="" /></Link> */}
                <Link onClick={openModal}><img src={WeChat} alt="" /></Link>
                <Link target="blank" to="https://www.instagram.com/solongoearlyintervention/"><img src={footcamera} alt="" /></Link>
              </div>
              <ul style={{listStyle: "none"}}>
                <li><a href="https://solongoearlyintervention.com.au/">Early Childhood Intervention Program</a></li>
                <li><a href="https://solongoearlyintervention.com.au/Therapy">ESDM Certified Therapists</a></li>
                <li><a href="https://solongoearlyintervention.com.au/play-therapy">Play Based Therapy</a></li>
                <li><a href="https://solongoearlyintervention.com.au/child-autism-therapy">Autism Specialists</a></li>
                <li><a href="https://solongoearlyintervention.com.au/autism-assessment">Autism Spectrum Assessment</a></li>
                <li><a href="https://solongoearlyintervention.com.au/">Early Childhood Intervention Services</a></li>
              </ul>

            </div>
            </div>
            {modal && (
              <div className="modal modal-wechat">
                <div className="modal-content-wechat">
                  <div onClick={closeModal} className="close-btn-man"><FontAwesomeIcon icon={faXmark} /></div>
                  <img  src={wechatscan} alt="" />
                </div>
              </div>
            )}
        </footer>
      </div>
    </>
  );
};

export default Footer;
